import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faInstagram, faUber } from "@fortawesome/free-brands-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo from "../img/logo.jpg";

function Navbar() {
  const menu = [
    { title: "Home", link: "#header" },
    //{ title: "Menu", link: "#menu" },
    //{ title: "Photo Gallery", link: "#gallery" },
    { title: "About", link: "#aboutUs" },
    { title: "Contact", link: "#contactUs" },
  ]

  const social = [
    { title: "Facebook", link: "https://www.facebook.com/lasautenticas604", icon: <FontAwesomeIcon icon={faFacebookF} /> },
    { title: "Instagram", link: "https://www.instagram.com/lasautenticas604", icon: <FontAwesomeIcon icon={faInstagram} /> },
  ]

  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <nav className="flex flex-wrap items-center justify-between px-2 py-3 bg-white/80 backdrop-blur-xl shadow-xl top-0 z-50 w-full h-20 fixed">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="px-1 static block justify-start w-1/6">
          <a
            className="flex items-center"
            href="#header"
          >
            <img src="\svgs\logo.svg" alt="logo" className="min-w-14 w-14" />
          </a>
        </div>
        <div
          className=

          "w-4/6 flex flex-grow items-center rounded-xl shadow-none top-0 right-0 relative mt-0 mr-0 max-w-max h-full z-50 overflow-y-auto"
          id="example-navbar-warning"
        >
          <ul className="flex list-none min-w-fit">
            {menu.map((item, index) => (
              <li key={index} className="nav-item min-w-fit">
                <a
                  className="px-3 py-2 flex items-center uppercase font-bold leading-snug text-orange-700 hover:opacity-75"
                  href={item.link}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex w-1/6 items-center">
          <ul className="flex flex-col lg:flex-row list-none ml-auto">
            {social.map((item, index) => (
              <li key={index} className="nav-item">
                <a
                  className="px-3 py-2 flex items-center uppercase font-bold leading-snug text-orange-700 hover:opacity-75"
                  href={item.link}
                >
                  {item.icon}
                </a>
              </li>
            ))}
          </ul>

        </div>
      </div>
    </nav >
  );
}

export default Navbar;
