import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faInstagram, faUber } from "@fortawesome/free-brands-svg-icons";
import mapa from '../img/map.png'

function ContactUs() {
    const social = [
        { title: "Facebook", link: "https://www.facebook.com/lasautenticas604", icon: <FontAwesomeIcon icon={faFacebookF} /> },
        { title: "Instagram", link: "https://www.instagram.com/lasautenticas604", icon: <FontAwesomeIcon icon={faInstagram} /> },
    ]

    return (
        <div id="contactUs" className="text-gray-600 body-font relative h-screen flex flex-col snap-always snap-center">
            <div className="bg-black/40 basis-2/4">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2126.931027337963!2d-123.14304715871175!3d49.267349734353274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548673fefd42ff51%3A0x9619d97e3e2f107c!2sLas%20Autenticas%20Tacos%20y%20Tortas%20Ahogadas!5e0!3m2!1ses-419!2smx!4v1655082217200!5m2!1ses-419!2smx"
                    className="border-0 w-full h-full"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <div className="bg-gray-100 backdrop-blur-lg basis-2/4 p-4">
                <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Contact Us</h2>
                <div className="bg-transparent relative flex flex-wrap">
                    <a href="https://goo.gl/maps/NCP7trb3pHB9NkSE9" target="_blank" className="hover:underline md:w-1/3 w-full">
                        <div className="w-full">
                            <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                            <p className="mt-1">1605 West 5th Avenue, Vancouver, BC V6J 1N5</p>
                        </div>
                    </a>
                    <a href="mailto:lasautenticas604@gmail.com" target="_blank" className="hover:underline leading-relaxed md:w-1/3 w-full">
                        <div className="">
                            <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">EMAIL</h2>
                            lasautenticas604@gmail.com
                        </div>
                    </a>
                    <div className="md:w-1/3 w-full">
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                        <p className="leading-relaxed">1-604-862-5951</p>
                    </div>
                    <div className="w-1/2 md:w-1/4">
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">9am-11pm</h2>
                        <p className="leading-relaxed text-xs">Tuesday, Wednesday, Thursday</p>
                    </div>
                    <div className="w-1/2 md:w-1/4">
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">9am-1am</h2>
                        <p className="leading-relaxed text-xs">Friday, Saturday</p>
                    </div>
                    <div className="w-1/2 md:w-1/4">
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">9am-11pm</h2>
                        <p className="leading-relaxed text-xs">Sunday</p>
                    </div>
                    <div className="w-1/2 md:w-1/4">
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">Closed</h2>
                        <p className="leading-relaxed text-xs">Monday</p>
                    </div>

                    {social.map((item, index) => (
                        <div key={index} className="w-1/2 text-center pt-10">
                            <a
                                className="uppercase font-bold leading-snug hover:underline text-xl"
                                href={item.link}
                            >
                                <span className="mr-2">{item.icon}</span>{item.title}
                            </a>
                        </div>
                    ))}

                </div>
            </div>

        </div>
    )
}
export default ContactUs;