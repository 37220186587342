import 'react-slideshow-image/dist/styles.css';
import foto_01 from '../fotos/foto_01.jpeg';
import foto_02 from '../fotos/foto_02.jpeg';
import foto_03 from '../fotos/foto_03.jpeg';
import foto_04 from '../fotos/foto_04.jpeg';
import foto_05 from '../fotos/foto_05.jpeg';
import foto_06 from '../fotos/foto_06.jpeg';
import { Disclosure } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSeedling, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

const elMenu = [
    {
        title: 'SIGNATURE ITEMS', sub_menu: [
            {
                title: <>Torta Ahogada <span className='font-semibold'>"Hangover cure"</span></>, price: "$17.25"
            },
            {
                title: <>Deep fried taco <span className='text-xs'>(Beans - potato)</span></>,
                price: "$4.75", veggan: true,
                subsub_menu: [{
                    title: <span className='ml-2 absolute text-lg'>Add Carnitas</span>,
                    price: "+ $3.25"
                }]
            },

            { title: "Quesabirria", price: "$6.50" },
            { title: "Classic Birria taco", price: "$5.50" },
            { title: <>Botana <span className='text-xs'>(Guacamole Pico de gallo, salsa & chips)</span></>, price: "$10.00" },
        ]
    },
    {
        title: <>Tacos <span className='text-lg'>(Corn Soft tortilla 4")</span></>,
        sub_menu: [
            { title: "Asada", price: "$3.75" },
            { title: "Birria", price: "$3.75" },
            { title: "Campechano", price: "$3.75" },
            { title: "Carnitas", price: "$3.75" },
            { title: "Chorizo", price: "$3.75" },
            { title: "Pastor", price: "$3.75" },
            { title: "Vegan Pastor", price: "$3.75", veggan: true },
        ]
    },
    {
        title: <>Quesadillas <span className='text-lg'>(Flour tortilla 10")</span></>,
        sub_menu: [
            { title: "Asada", price: "$10.00" },
            { title: "Birria", price: "$10.00" },
            { title: "Campechano", price: "$10.00" },
            { title: "Carnitas", price: "$10.00" },
            { title: "Chorizo", price: "$10.00" },
            { title: "Pastor", price: "$10.00" },
        ]
    },
    {
        title: <>Quesadillas <span className='text-lg'>(Corn tortilla 4")</span></>,
        sub_menu: [
            { title: "Quesa-asada", price: "$6.50" },
            { title: "Quesa-campechana", price: "$6.50" },
            { title: "Quesa-carnitas", price: "$6.50" },
            { title: "Quesa-chorizo", price: "$6.50" },
            { title: "Quesa-pastor", price: "$6.50" },
        ]
    },
    {
        title: <>Gringas</>,
        sub_menu: [
            { title: "Asada", price: "$16.00" },
            { title: "Birria", price: "$16.00" },
            { title: "Campechano", price: "$16.00" },
            { title: "Carnitas", price: "$16.00" },
            { title: "Chorizo", price: "$16.00" },
            { title: "Pastor", price: "$16.00" },
        ]
    },
    {
        title: <>Volcanes <span className='text-lg'>(Corn Soft tortilla 4")</span></>,
        sub_menu: [
            { title: "Asada", price: "$4.25" },
            { title: "Birria", price: "$4.25" },
            { title: "Campechano", price: "$4.25" },
            { title: "Carnitas", price: "$4.25" },
            { title: "Chorizo", price: "$4.25" },
            { title: "Pastor", price: "$4.25" },
        ]
    },
    {
        title: <>Chilaquiles</>,
        sub_menu: [
            { title: "Asada", price: "$18.00" },
            { title: "Birria", price: "$18.00" },
            { title: "Vegan Pastor", price: "$18.00", veggan: true },
            { title: "Carnitas", price: "$18.00" },
            { title: "Pastor", price: "$18.00" },
        ]
    },
    {
        title: <>BY ORDER <span className='text-lg'>(1 Person)</span></>, sub_menu: [
            { title: "Order Asada", price: "$25.00" },
            { title: "Order Birria", price: "$25.00" },
            { title: "Order Campechano", price: "$23.00" },
            { title: "Order Carnitas", price: "$23.00" },
            { title: "Order Chorizo", price: "$23.00" },
            { title: "Order Pastor", price: "$23.00" },
            { title: "Order Vegan Pastor", price: "$23.00", veggan: true },
            { title: <span className='text-sm'>(Orders includes corn tortillas, lime, salsa, onion and cilantro)</span> }
        ]
    },
    {
        title: 'Extras', sub_menu: [
            {
                title: <>Consomé <span className='text-xs'>(Birria, onion, lime
                    and cilantro )</span></>,
                subsub_menu: [
                    {
                        title: <span className='ml-4 absolute text-lg'>3oz</span>,
                        price: "$1.25"
                    },
                    {
                        title: <span className='ml-4 absolute text-lg'>1/4</span>,
                        price: "$4.50"
                    },
                    {
                        title: <span className='ml-4 absolute text-lg'>1/2</span>,
                        price: "$7.75"
                    },
                    {
                        title: <span className='ml-4 absolute text-lg'>1 lt.</span>,
                        price: "$13.00"
                    },
                ]
            },
            {
                title: <>Fried onions</>,
                subsub_menu: [
                    {
                        title: <span className='ml-4 absolute text-lg'>3oz</span>,
                        price: "$1.25"
                    },
                    {
                        title: <span className='ml-4 absolute text-lg'>1/4</span>,
                        price: "$4.50"
                    },
                ]
            },
            {
                title: <>Salsa Taquera</>,
                subsub_menu: [
                    {
                        title: <span className='ml-4 absolute text-lg'>3oz</span>,
                        price: "$1.75"
                    },
                    {
                        title: <span className='ml-4 absolute text-lg'>1/4</span>,
                        price: "$6.50"
                    },
                    {
                        title: <span className='ml-4 absolute text-lg'>1/2</span>,
                        price: "$11.50"
                    },
                    {
                        title: <span className='ml-4 absolute text-lg'>1 lt.</span>,
                        price: "$21.00"
                    },
                ]
            },
        ]
    },
    {
        title: <>Drinks</>, sub_menu: [
            { title: "Agua Fresca 1 lt.", price: "$9.00" },
            { title: "Coke", price: "$3.00" },
            { title: "Ginger ale", price: "$3.00" },
            { title: "Jarritos", price: "$3.50" },
        ]
    },

];

function Menu() {
    return (
        <div className="grid grid-cols-1 bg-gradient-to-br from-brown-700 to-brown-800" id="menu">
            <div className="flex flex-col justify-between leading-normal relative w-full text-center h-20">
                <h5 className="text-3xl font-bold tracking-tight text-gray-100 my-auto">Menu</h5>
            </div>
            {elMenu.map((item, index) => (
                <Disclosure key={index} >
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex flex-col bg-white border shadow-md md:flex-row hover:bg-gray-100 m-0">
                                <div className="flex flex-col justify-between p-2 leading-normal relative w-full text-left">
                                    {item.price ? <p className="text-gray-100 bg-orange-700 w-fit rounded-full p-1 absolute top-2 right-7">{item.price}</p> : null}
                                    <p className="text-gray-700 w-fit rounded-full p-1 absolute top-1 right-1"><FontAwesomeIcon icon={open ? faSortUp : faSortDown} /></p>
                                    <h5 className="text-2xl font-bold tracking-tight text-gray-900">{item.title} {item.veggan ? <FontAwesomeIcon icon={faSeedling} className="text-green-500" /> : null}</h5>
                                </div>
                            </Disclosure.Button>
                            <Disclosure.Panel className="relative">
                                {item.sub_menu ? item.sub_menu.map((itemSub, indexSub) => (
                                    <div key={indexSub} className="flex flex-col justify-between p-2 leading-normal relative w-full text-left bg-gray-200 border-b-2 border-gray-300 pl-4">
                                        {itemSub.price ? <p className="text-gray-100 bg-orange-700 w-fit rounded-full p-1 absolute top-2 right-1">{itemSub.price}</p> : null}
                                        <h5 className="text-xl tracking-tight text-gray-900">{itemSub.title} {itemSub.veggan ? <FontAwesomeIcon icon={faSeedling} className="text-green-500" /> : null}</h5>
                                        {itemSub.subsub_menu ? itemSub.subsub_menu.map((itemsubsub, indexsubsub) => (
                                            <div key={indexsubsub} className="flex flex-col justify-between my-2 leading-normal relative w-full text-left bg-gray-200 h-5">
                                                {itemsubsub.price ? <p className="text-gray-100 bg-orange-700 w-fit rounded-full p-1 absolute top-0 -right-1">{itemsubsub.price}</p> : null}
                                                <h5 className="text-xl tracking-tight text-gray-900">{itemsubsub.title} {itemsubsub.veggan ? <FontAwesomeIcon icon={faSeedling} className="text-green-500" /> : null}</h5>
                                            </div>
                                        )) : null}
                                    </div>
                                )) : null}

                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            ))
            }
        </div >
    )

}
export default Menu;