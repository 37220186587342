import foto_01 from '../fotos/foto_01.jpeg';
import foto_02 from '../fotos/foto_02.jpeg';
import foto_03 from '../fotos/foto_03.jpeg';
import foto_04 from '../fotos/foto_04.jpeg';
import foto_05 from '../fotos/foto_05.jpeg';
import foto_06 from '../fotos/foto_06.jpeg';
import foto_07 from '../fotos/foto_07.jpeg';
import foto_08 from '../fotos/foto_08.jpeg';
import foto_09 from '../fotos/foto_09.jpeg';
import foto_10 from '../fotos/foto_10.jpeg';
import foto_11 from '../fotos/foto_11.jpeg';

import img_010 from '../fotos/img_010.jpeg';
import img_003 from '../fotos/img_003.jpeg';
import img_008 from '../fotos/img_008.jpeg';
import img_007 from '../fotos/img_007.jpeg';
import img_004 from '../fotos/img_004.jpeg';
import img_012 from '../fotos/img_012.jpeg';
import img_009 from '../fotos/img_009.jpeg';
import img_011 from '../fotos/img_011.jpeg';
import img_001 from '../fotos/img_001.jpeg';
import img_002 from '../fotos/img_002.jpeg';
import img_006 from '../fotos/img_006.jpeg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

const slideImages = [
    foto_01,
    foto_02,
    foto_03,
    foto_04,
    foto_05,
    foto_06,
    foto_07,
    foto_08,
    foto_09,
    foto_10,
    foto_11,
    img_010,
    img_003,
    img_008,
    img_007,
    img_004,
    img_012,
    img_009,
    img_011,
    img_001,
    img_002,
    img_006,
];

slideImages.sort(() => Math.random() - 0.5);
function Gallery() {
    const [muestra, setMuestra] = useState(false);

    const muestraEsta = (item, index) => {
        setMuestra(!muestra);
    }

    return (
        <div style={{ marginRight: "0px" }} className="not-prose relative bg-gradient-to-tr from-slate-700 to-slate-900 overflow-y-auto h-screen w-screen border-t-slate-800 border-solid border-t-2 border-b-slate-800 border-b-2">
            <div className="relative rounded-xl overflow-auto p-8 ">
                <div className="relative">
                    <div className="columns-3xs gap-8 space-y-8">
                        {
                            slideImages.map((item, index) => (
                                <div key={index + item} className="relative aspect-w-16 aspect-h-9 cursor-pointer hover:brightness-125 active:brightness-90 group">
                                    <img className="w-full object-cover rounded-lg shadow-lg group-hover:shadow-2xl" src={item} />
                                    <div className="absolute inset-0 ring-1 ring-inset ring-black/10 rounded-lg"></div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Gallery;