import React from 'react';
import './App.css';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Header from './components/Header';
import Menu from './components/Menu';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Gallery from './components/Gallery';

function App() {
  return (
    <div className='snap-y snap-mandatory scroll-smooth'>
      <Navbar />
      <Header />
      <Gallery />
      <AboutUs />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
