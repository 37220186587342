function AboutUs() {
    return (
        <div id="aboutUs" className="text-gray-600 body-font relative h-screen overflow-auto snap-always snap-center">
            <div className="container px-5 py-24 mx-auto flex flex-col">
                <div className="lg:w-4/6 mx-auto">
                    <div className="flex flex-col sm:flex-row mt-10">
                        <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                            <div className="w-22 rounded-full inline-flex items-center justify-center ">
                                <img src="\svgs\logo.svg" alt="logo" className="w-56" />
                            </div>
                            <div className="flex flex-col items-center text-center justify-center">
                                <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">About Us</h2>
                                <div className="w-12 h-1 bg-orange-500 rounded mt-2 mb-4"></div>
                                <p className="text-base">Ipsum exercitation non dolore eiusmod cupidatat culpa qui dolor in amet voluptate ea duis.</p>
                            </div>
                        </div>
                        <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                            <p className="leading-relaxed text-lg mb-4">Sunt proident ea occaecat eiusmod excepteur nostrud nulla reprehenderit exercitation deserunt aliqua et. Est commodo occaecat fugiat dolor Lorem. Eiusmod est nostrud sint nulla ea exercitation. Aute consectetur dolore commodo eu nostrud qui irure veniam tempor ex laboris labore eiusmod eiusmod.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutUs;