import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faInstagram, faUber } from "@fortawesome/free-brands-svg-icons";

import logo from "../img/logo.jpg";

const social = [
    { title: "Facebook", link: "https://www.facebook.com/lasautenticas604", icon: <FontAwesomeIcon icon={faFacebookF} /> },
    { title: "Instagram", link: "https://www.instagram.com/lasautenticas604", icon: <FontAwesomeIcon icon={faInstagram} /> },
]

function Footer() {
    return (
        <footer className="text-gray-600 body-font">
            <div className="container px-2 py-3 mx-auto flex items-center sm:flex-row flex-col">
                <p className="text-sm text-gray-500 sm:ml-2 sm:pl-2 sm:py-2 sm:mt-0 mt-2">© 2022 Las Autenticas — All rights reserved</p>
            </div>
        </footer>
    )
}
export default Footer;