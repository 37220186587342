import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';
import foto_01 from '../fotos/foto_01.jpeg';
import foto_02 from '../fotos/foto_02.jpeg';
import foto_03 from '../fotos/foto_03.jpeg';
import foto_04 from '../fotos/foto_04.jpeg';
import foto_05 from '../fotos/foto_05.jpeg';
import foto_06 from '../fotos/foto_06.jpeg';
import foto_07 from '../fotos/foto_07.jpeg';
import foto_08 from '../fotos/foto_08.jpeg';
import foto_09 from '../fotos/foto_09.jpeg';
import foto_10 from '../fotos/foto_10.jpeg';
import foto_11 from '../fotos/foto_11.jpeg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';

const slideImages = [
    foto_01,
    foto_02,
    foto_03,
    foto_04,
    foto_05,
    foto_06,
    foto_07,
    foto_08,
    foto_09,
    foto_10,
    foto_11
];

function Header() {
    return (
        <div id="header" className="w-screen h-screen bg-gradient-to-br from-brown-700 to-brown-900 flex justify-center items-center mt-19 snap-always snap-center">
            <div className="bg-gradient-to-br from-brown-700 to-brown-900 w-screen h-screen relative z-0 aspect-video">
                <Fade easing="ease" arrows={false}>
                    {
                        slideImages.map((item, index) => (
                            <div className="each-slide" key={index}>
                                <div className='backdrop-opacity-80 brightness-75' style={{ 'backgroundImage': `url(${item})`, backgroundPosition: 'center', backgroundSize: 'cover', height: 'calc(100vh)' }}>
                                </div>
                            </div>
                        ))
                    }
                </Fade>
                <div className="absolute inset-0 m-auto w-screen h-screen z-10">
                    <div className="text-center text-white text-shadow-xl m-auto w-full h-screen grid lg:grid-cols-2 grid-cols-1 align-middle">
                        <div className='grid grid-cols-1 my-auto mt-auto'>
                            <img src="\svgs\logo.svg" className="w-4/6 mr-auto ml-auto" />
                        </div>

                        <div className='grid grid-cols-3 my-auto mx-auto'>
                            <a href="https://www.ubereats.com/ca/store/las-autenticas-tacos-%26-tortas-vancouver/G38UyxoDTGO1SWAnCm9siQ" className="w-full sm:p-5 p-2 rounded-full" >
                                <img src="\svgs\uber.svg" />
                            </a>
                            <a href="https://www.skipthedishes.com/las-authenticas-tacos-y-tortas-ahogadas-1605" className="w-full sm:p-5 p-2 rounded-full" >
                                <img src="\svgs\skip.svg" />
                            </a>
                            <a href="https://www.doordash.com/store/2557551" className="w-full sm:p-5 p-2 rounded-full" >
                                <img src="\svgs\door.svg" />
                            </a>
                        </div>
                        <div className='grid grid-cols-1 absolute bottom-0 mb-6 my-auto text-center w-full text-sm text-shadow-lg'>
                            ALL OUR FOOD IS FRESH AND MADE TO ORDER
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default Header;